import React from 'react';
import { ToastProps } from '../../../../components/Toast/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEtablissementModels } from '../../../../models/services/module.etablissement';
import isObjectEmpty from '../../../../utils/IsEmptyObject';
import { languageData } from '../../../../i18n';
import ButtonElement from '../../../../components/button/button';
import RightArrowIconWhite from '../../../../assets/icons/RightArrowIconWhite.svg';
import RightArrowIconBlack from '../../../../assets/icons/RightArrowIconBlack.svg';
import { serviceTaxesModels } from '../../../../models/services/module.taxesInfos';
import { updateStepProperties } from '../../../../utils/UpdateFormHeaderSteps';
import ToastHandler from '../../Toast/ToastHandler';
import { renderButtonText } from '../../FormWizzard/FormSteps/FooterButtonsTextRenderer';
import { isValidFiscalInformation } from '../../../../utils/isValidFiscalInformation';
import useHandleCancel from './FormSteps/ResetAddForm';
import {
  addEtablissement,
  addEtablissementCurrentStep,
  resetVerifResponseEtablissement,
  setStepsDataEtablissement,
  setValidationRequestedEtablissement,
  setValidationRequestedFiscalEtablissement,
  setValidationRequestedStep2Etablissement,
} from '../../../../store/actions/actions';
import { IsTabFormDirty } from '../../../../services/tabs/IsTabFormDirty';
import { serviceEntrepriseModels } from '../../../../models/services/module.entreprise';
import { useUnsavedChanges } from '../useUnsavedChanges';
import tabs_list from '../../../../data/tabsList';
/**
 * FormWizzardFooter component description.
 *
 * @FormWizzardFooter
 *
 */
const FormWizzardFooter: React.FC = () => {
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  const dispatch = useDispatch();
  const handleCancel = useHandleCancel();
  const { newFormData, unsavedChangesData } = useUnsavedChanges();

  const addSuccess = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addSuccess,
  );
  const verifResponse = useSelector(
    (state: { taxesInfos: serviceTaxesModels.TaxesInfosState }) =>
      state.taxesInfos.taxesInfosResponseEtablissement,
  );
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const companyId = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.selectedExistingCompanyCreateEtablissement,
  );

  const company_id = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.companySheet.id,
  );


  const combineEtablissement = useSelector(
    (state: {
      combineEtablissementReducer: { combineEtablissement: boolean };
    }) => state.combineEtablissementReducer.combineEtablissement,
  );

  const stepsData = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.stepsDataEtablissement,
  );

  const handleSubmitData = () => {
    dispatch(resetVerifResponseEtablissement());
    const tabFormDirtyIndex = IsTabFormDirty({
      array: unsavedChangesData,
      key: tabs_list[1].name,
    });
    
    const data = {
      ...(unsavedChangesData[tabFormDirtyIndex].value.payload
        .payload as serviceEtablissementModels.Etablissement),
      //companyId: combineEtablissement ? company_id : companyId,
      companyId: company_id,
    };

    dispatch(addEtablissement(data));
  };
  React.useEffect(() => {
    if (addSuccess === true) {
      dispatch(addEtablissementCurrentStep(3));
    }
  }, [addSuccess]);
  const addSucceeded = () => {
    handleStepUpdate(2);
    dispatch(addEtablissementCurrentStep(3));
    toastRef.current?.openToast(
      'success',
      languageData.global.Form.successMsg.addEtreprise,
    );
  };
  const handleFinishForm = () => {
    {
      addSuccess ? addSucceeded() : toastRef.current?.closeToast();
    }
  };
  const handleValidationRequest = () => {
    if (addEtablissementStep === 1) {
      isValidFiscalInformation(verifResponse)
        ? dispatch(setValidationRequestedEtablissement(true))
        : dispatch(setValidationRequestedFiscalEtablissement(true));
    } else {
      dispatch(setValidationRequestedStep2Etablissement(true));
    }
  };
  const handleStepUpdate = (number: number) => {
    const updatedStepsData = updateStepProperties(stepsData, number);
    dispatch(setStepsDataEtablissement(updatedStepsData));
  };

  const handleStep1Follow = () => {
    dispatch(addEtablissementCurrentStep(2));
  };

  const handleStep2Follow = () => {
    handleStepUpdate(1);
    handleSubmitData();
    handleFinishForm();
  };
  const handleFollowClick = () => {
    const { generalInformation, fiscalInformation,financialInformation } = newFormData?.payload;

    handleValidationRequest();
    if (generalInformation && !isObjectEmpty(generalInformation)) {
      handleStepUpdate(0);
      if (addEtablissementStep === 1) {
        handleStep1Follow();
      } else if (
        addEtablissementStep === 2 &&
        financialInformation &&
        !isObjectEmpty(fiscalInformation)
      ) {
        handleStep2Follow();
      }
    }
  };

  
  const handleGoBackClick = () => {
    dispatch(addEtablissementCurrentStep(addEtablissementStep - 1));
  };

  return (
    <div className="FormWizzardFooter">
      <div className="footer-wrapper fl_row jc_fe gp10">
        <ButtonElement
          type="TextButton"
          text={renderButtonText('cancel', addEtablissementStep)}
          buttonSize="small"
          buttonStyle="btn-primary-light"
          onClick={
            addEtablissementStep === 1 ? handleCancel : handleGoBackClick
          }
          //isDisabled={addEtablissementStep === 1}
        />
        {isValidFiscalInformation(verifResponse) ? (
          <ButtonElement
            type="IconButton"
            text={renderButtonText('next', addEtablissementStep)}
            buttonSize="small"
            buttonStyle="btn-primary"
            onClick={handleFollowClick}
            icon={RightArrowIconBlack}
            //  isDisabled={true}
          />
        ) : (
          <ButtonElement
            type="IconButton"
            text={renderButtonText('next', addEtablissementStep)}
            buttonSize="small"
            buttonStyle="btn-black-light"
            onClick={handleFollowClick}
            icon={RightArrowIconWhite}
            isDisabled={true}
          />
        )}
      </div>
      <ToastHandler ref={toastRef} />
    </div>
  );
};

export default FormWizzardFooter;
