import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddProductIconBlue from '../../assets/icons/AddProductIconBlue.svg';
import AddProductIcon from '../../assets/icons/AddProductIconLightGrey.svg';
import EtapesIcon from '../../assets/icons/EtapesIcon.svg';
import MenuIcon from '../../assets/icons/MenuIcon.svg';
import SearchIconGreyInp from '../../assets/icons/SearchIconGreyInp.svg';
import AddProduct from '../../components/Catalogue/Articles/AddProduct';
import InputField from '../../components/Input/InputCatalogue';
import { languageData } from '../../i18n';
import { Menu, level } from '../../models/services/module.catalogue';
import {
  changeSidebarMenuMode,
  dragMenuLevel,
  dragSubMenu,
  getAlllevels,
  startGetMenu,
  updateDeletedMenuLevels,
  updateDeletedSubMenus,
  updateMenuLevels,
  updateSubMenus,
} from '../../store/actions/catalogues/actions';
export default function NavMenu(props: any) {
  const [activeTab, setActiveTab] = useState('menus');
  const [levels, setlevels] = useState<level[]>([]);
  const [levelsResult, setlevelsResult] = useState<level[]>();
  const [menusResult, setmenusResult] = useState<Menu[]>();
  const [menus, setmenus] = useState<Menu[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const deletedMenuLevels = useSelector(
    (state: { menuLevelsStates: { deletedMenuLevels: level[] } }) =>
      state.menuLevelsStates.deletedMenuLevels,
  );
  const menuSideBar = useSelector(
    (state: { uiCatalogue: { menuSideBar: string } }) =>
      state.uiCatalogue.menuSideBar,
  );
  const menuLevelsChange = useSelector(
    (state: { menuLevelsStates: { menuLevelsChange: number } }) =>
      state.menuLevelsStates.menuLevelsChange,
  );
  const pathname = props.currentPath ?? '';
  const id = pathname.split('/').pop();

  const menuLevelsDragged = useSelector(
    (state: { menuLevelsStates: { menuLevels: level[] } }) =>
      state.menuLevelsStates.menuLevels,
  );
  const subMenuDragged = useSelector(
    (state: { subMenuStates: { subMenus: Menu[] } }) =>
      state.subMenuStates.subMenus,
  );
  const deletedSubMenu = useSelector(
    (state: { subMenuStates: { deletedsubMenu: Menu[] } }) =>
      state.subMenuStates.deletedsubMenu,
  );
  const fetchLevels = () => {
    dispatch(
      getAlllevels((_) => {
        setlevels(withDisabledDragged(_ as level[], menuLevelsDragged));
      }),
    );
  };
  const fetchMenus = () => {
    dispatch(
      startGetMenu((_) => {
        setmenus(withDisabledSubMenuDragged(_ as Menu[], subMenuDragged));
      }),
    );
  };
  useEffect(() => {
    fetchLevels();
    dispatch(changeSidebarMenuMode('menus'));
  }, []);
  useEffect(() => {
    if (activeTab == 'menus') {
      fetchMenus();
    }
  }, [activeTab]);
  useEffect(() => {
    if (menuLevelsDragged) {
      console.log(
        'withDisabledDragged(levels, menuLevelsDragged)',
        withDisabledDragged(levels, menuLevelsDragged),
      );
      setlevels(withDisabledDragged(levels, menuLevelsDragged));
    }
  }, [menuLevelsDragged, menuLevelsChange]);
  useEffect(() => {
    if (menuSideBar && menuSideBar != activeTab) {
      setActiveTab(menuSideBar ?? 'menus');
    }
  }, [menuSideBar]);

  useEffect(() => {
    if (subMenuDragged) {
      setmenus([
        ...withDisabledSubMenuDragged([...menus], [
          ...subMenuDragged,
        ] as Menu[]),
      ]);
    }
  }, [subMenuDragged]);
  const withDisabledSubMenuDragged = (
    menus: Menu[],
    menuDragged: Menu[],
  ): Menu[] =>
    menus?.map((l) => {
      const disabledSet = new Set(menuDragged?.map((l) => l.idSubMenu ?? l.id));
      disabledSet.add(parseInt(id as string));
      return {
        ...l,
        selected: false,
        disabled: disabledSet.has(l.idSubMenu ?? l.id),
      } as Menu;
    });
  const withDisabledDragged = (
    menuLevels: level[],
    menuLevelsDragged: level[],
  ): level[] =>
    menuLevels?.map((l) => {
      const disabledSet = new Set(
        menuLevelsDragged?.map((l) => l.idLevel ?? l.id),
      );
      disabledSet.add(parseInt(id ?? ''));
      return {
        ...l,
        selected: false,
        disabled: disabledSet.has(l.idLevel ?? l.id),
      };
    });
  const onDrag = (level: level) => {
    let selectedLevel =
      deletedMenuLevels.findIndex(
        (c) => (c.idLevel ?? c.id) == (level.idLevel ?? level.id),
      ) > -1
        ? deletedMenuLevels[
            deletedMenuLevels.findIndex(
              (c) => (level.idLevel ?? level.id) == (c.idLevel ?? c.id),
            )
          ]
        : level;
    if (
      deletedMenuLevels.findIndex(
        (c) => (c.idLevel ?? c.id) == (level.idLevel ?? level.id),
      ) > -1
    ) {
      dispatch(
        updateDeletedMenuLevels(
          deletedMenuLevels.filter(
            (i) => (i.idLevel ?? i.id) != (level.idLevel ?? level.id),
          ),
        ),
      );
    }
    dispatch(dragMenuLevel(selectedLevel as level));
  };
  const onDragSubMenu = (menu: Menu) => {
    let selectedMenu =
      deletedSubMenu.findIndex(
        (c) => (c.idSubMenu ?? c.id) == (menu.idSubMenu ?? menu.id),
      ) > -1
        ? deletedSubMenu[
            deletedSubMenu.findIndex(
              (c) => (menu.idSubMenu ?? menu.id) == (c.idSubMenu ?? c.id),
            )
          ]
        : menu;
    if (
      deletedSubMenu.findIndex(
        (c) => (c.idSubMenu ?? c.id) == (menu.idSubMenu ?? menu.id),
      ) > -1
    ) {
      dispatch(
        updateDeletedSubMenus(
          deletedSubMenu.filter(
            (i) => (i.idSubMenu ?? i.id) != (menu.idSubMenu ?? menu.id),
          ),
        ),
      );
    }
    dispatch(dragSubMenu(selectedMenu as Menu));
  };
  const onSelect = (level: level, checked: boolean, all?: boolean) => {
    if (levels)
      setlevels(
        all
          ? levels.map((c) => ({ ...c, selected: checked }))
          : levels.map((c) =>
              c.id === level.id ? { ...c, selected: checked } : c,
            ),
      );
  };
  const onSelectMenu = (menu: Menu, checked: boolean, all?: boolean) => {
    if (menus)
      setmenus(
        all
          ? menus.map((c) => ({ ...c, selected: checked }))
          : menus.map((c) =>
              c.id === menu.id ? { ...c, selected: checked } : c,
            ),
      );
  };
  const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect({} as level, e.target.checked, true);
  };
  const onSelectAllMenus = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectMenu({} as Menu, e.target.checked, true);
  };
  const onDragSelected = (levels: level[]) => {
    // dispatch(updateDeletedIngredients(updatedDeletedIngredients));
    dispatch(updateMenuLevels([...menuLevelsDragged, ...levels]));
  };
  const onDragAll = () => {
    onDragSelected(levels.filter((_) => _.selected && !_.disabled));
  };
  const onDragAllMenus = () => {
    onDragSelectedMenus(menus.filter((_) => _.selected && !_.disabled));
  };
  const handleChangeNavMode = (mode: string) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setActiveTab(mode);
    dispatch(changeSidebarMenuMode(mode));
  };
  const onDragSelectedMenus = (menus: Menu[]) => {
    // dispatch(updateDeletedIngredients(updatedDeletedIngredients));
    dispatch(updateSubMenus([...subMenuDragged, ...menus]));
  };
  const iconDraggable = () => {
    return activeTab == 'etapes'
      ? !levels?.find((_) => _.selected)
        ? AddProductIcon
        : AddProductIconBlue
      : !menus?.find((_) => _.selected)
        ? AddProductIcon
        : AddProductIconBlue;
  };
  const isActiveButton = () => {
    return activeTab == 'etapes'
      ? !levels?.find((_) => _.selected)
      : !menus?.find((_) => _.selected);
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let __ = e.target.value.toLowerCase();
    activeTab == 'etapes'
      ? setlevelsResult(
          withDisabledDragged(
            __ == ''
              ? levels
              : levels.filter((_) => _.designation.toLowerCase().includes(__)),
            menuLevelsDragged,
          ),
        )
      : setmenusResult(
          withDisabledSubMenuDragged(
            __ == ''
              ? menus
              : menus.filter((_) => _.designation.toLowerCase().includes(__)),
            subMenuDragged,
          ),
        );
  };

  return (
    <div className="NavMenu h-full">
      <div className="nav-wrapper pd12">
        <div className="wrapper-header fl_row">
          <div
            className={`menus menu-item c-p pd10 fl_row ai_c gp10 fl-1 ${activeTab === 'menus' ? 'active-menu' : ''}`}
            onClick={() =>
              !(activeTab == 'etapes' && menuLevelsDragged.length > 0) &&
              handleChangeNavMode('menus')
            }
          >
            <img src={MenuIcon} alt="" />
            <p className="p14">{languageData.fr.navMenu.menus}</p>
          </div>
          <div
            className={`etapes menu-item c-p pd10 fl_row ai_c gp10 fl-1 ${activeTab === 'etapes' ? 'active-menu' : ''}`}
            onClick={() =>
              !(activeTab == 'menus' && subMenuDragged.length > 0) &&
              handleChangeNavMode('etapes')
            }
          >
            <img src={EtapesIcon} alt="" />
            <p className="p14">{languageData.fr.navMenu.etapes}</p>
          </div>
        </div>
        <div className="nav-wrapper">
          <div className="search-inp">
            <img className="icon-search" src={SearchIconGreyInp} alt="" />
            <InputField
              reff={inputRef}
              onChange={onSearch}
              type="text"
              style="search"
              placeholder={languageData.fr.navMenu.recherche}
            />
          </div>
          <div className="products-list pd20-t-b pd8-r-l">
            <div className="products-wrapper">
              <div className="header-products fl_row jc_s pd10-t-b">
                <div className="fl_row ai_c gp8 ">
                  <input
                    autoComplete="off"
                    disabled={
                      activeTab === 'etapes'
                        ? levels.filter((_) => !_.disabled)?.length == 0
                        : menus.filter((_) => !_.disabled)?.length == 0
                    }
                    type="checkbox"
                    name=""
                    checked={
                      activeTab === 'etapes'
                        ? levels.every((item) => item.selected)
                        : menus.every((item) => item.selected)
                    }
                    id=""
                    onChange={
                      activeTab == 'etapes' ? onSelectAll : onSelectAllMenus
                    }
                  />
                  <p className="p14 OxfordBlue02-txt">
                    {languageData.fr.navMenu.selectionnerTous}
                  </p>
                </div>
                <div
                  className="Select-all fl_row gp8 c-p"
                  onClick={activeTab == 'etapes' ? onDragAll : onDragAllMenus}
                >
                  <p
                    className={`p12  ${isActiveButton() ? 'LightSilver-txt' : ''}`}
                  >
                    {languageData.fr.navMenu.ajouter}
                  </p>{' '}
                  <img src={iconDraggable()} alt="" />
                </div>
              </div>
              {activeTab === 'etapes' && (
                <div className="etapes-tab">
                  <div className="listing-products fl_col gp12">
                    {(levelsResult ? levelsResult : levels)?.map(
                      (_, __) =>
                        !_.disabled && (
                          <AddProduct
                            selected={_.selected}
                            onDrag={() => onDrag(_)}
                            hideImg
                            name={_.designation}
                            onChange={(e) => onSelect(_, e.target.checked)}
                          />
                        ),
                    )}
                  </div>
                </div>
              )}
              {activeTab === 'menus' && (
                <div className="menus-tab">
                  <div className="listing-products fl_col gp12">
                    {(menusResult ? menusResult : menus)?.map(
                      (_, __) =>
                        !_.disabled && (
                          <AddProduct
                            selected={_.selected}
                            onDrag={() => onDragSubMenu(_)}
                            name={_.designation}
                            img={_.url}
                            onChange={(e) => onSelectMenu(_, e.target.checked)}
                          />
                        ),
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
