import React from 'react';
import CheckBox from '../../../components/Catalogue/Checkbox';

/**
 * ItemStock component description.
 *
 * @ItemStock
 *
 */

interface ItemStockProps {
  name: string;
  status?: 'exhausted' | 'sold-out-today';
  onClickCheck?: () => void;
}

const ItemStock: React.FC<ItemStockProps> = ({
  name,
  status,
  onClickCheck,
}) => {
  return (
    <div className="ItemStock rd8">
      <div className="item-wrapper fl_row ai_c  h-full gp16 pd8">
        <CheckBox boxType="squareBox" onChange={onClickCheck} />
        <div className="fl_col gp4">
          <p className="p16 w-500 Independence-txt mx1">{name}</p>
          {status && (
            <span className={`p12 w-500 status-card ${status}`}>
              {status === 'exhausted' ? 'Epuisé' : 'Epuisé Aujourd’hui'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemStock;
