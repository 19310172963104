import axios from 'axios';

export async function downloadImage(
  url: string,
  fileName = new Date().getTime() + 'image.jpg',
) {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();
  } catch (error) {
    console.error('Error downloading image:', error);
  }
}
