export const handleKeyDownNumber = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  const allowedKeysPattern =
    /[0-9]|Backspace|Tab|ArrowLeft|ArrowRight|Delete|Enter/;

  if (!allowedKeysPattern.test(event.key)) {
    event.preventDefault();
  }
};
export const onInputNumber = (event: React.FormEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement;
  target.value = target.value.replace(/[^0-9]*/g, '');
  onInputNumberStep2(event);
};
export const onInputNumberStep2 = (
  event: React.FormEvent<HTMLInputElement>,
) => {
  const target = event.target as HTMLInputElement;

  if (target.value.length > 10) {
    target.value = target.value.slice(0, 10);
  }
};
export const onKeyDownWithoutE = (evt: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
export const onKeyUpWithoutSymbol = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  const input = evt.currentTarget; // Get the input element
  const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/g; // List of symbols to remove

  // Replace symbols with an empty string
  input.value = input.value.replace(symbolRegex, '');
};
export const onKeyDownWithoutZero = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  const { value, selectionStart } = evt.currentTarget;
  if (['e', 'E', '+', '-'].includes(evt.key)) {
    evt.preventDefault();
    return;
  }
  if (evt.key === '0') {
    if (value === '' || (value.startsWith('0') && selectionStart === 1)) {
      evt.preventDefault();
    }
  }
};
export const handleInputPrice = (evt: React.FormEvent<HTMLInputElement>) => {
  const input = evt.currentTarget;

  // Remove invalid characters, keep digits, . and ,
  let value = input.value.replace(/[^0-9.,]/g, ''); // Allow digits, '.' and ','
  // Limit to two decimal places
  const parts = value.split(/[,\.]/);
  if (parts.length > 2) {
    value = parts[0] + '.' + parts.slice(1).join('').slice(0, 2); // Keep only two digits after the decimal
  } else if (parts.length === 2 && parts[1].length > 2) {
    value = parts[0] + '.' + parts[1].slice(0, 2); // Limit to two decimal places
  }

  if (value.startsWith('0') && !value.startsWith('0.')) {
    value = value.replace(/^0+/, '');
  }
  input.value = value;
};

export const onKeyUpRemoveZero = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  const input = evt.currentTarget;
  input.value = input.value.replace(/,/g, '');
  const periodIndex = input.value.indexOf('.');
  if (periodIndex !== -1) {
    input.value = input.value.slice(0, periodIndex);
  }
  input.value = input.value.replace(/^0+/, '') || '';
  const event = new Event('input', { bubbles: true });
  input.dispatchEvent(event);
};
export const removeDecimal = (onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Remove commas
    value = value.replace(/,/g, '');

    // Remove everything after the decimal point
    const periodIndex = value.indexOf('.');
    if (periodIndex !== -1) {
      value = value.slice(0, periodIndex);
    }

    // Remove leading zeros
    value = value.replace(/^0+/, '') || '';

    // Create a new event to pass the cleaned value to the original onChange
    const cleanedEvent = {
      ...e,
      target: {
        ...e.target,
        value: value.toString() // Set the cleaned value here
      }
    };
    
    onChange(cleanedEvent as React.ChangeEvent<HTMLInputElement>);

  };
};
