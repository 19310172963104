import React, { ReactNode } from 'react';
import FolderIconGrey from '../../assets/icons/FolderIconGrey.svg';
import RightArrowBoldIconGrey from '../../assets/icons/RightArrowBoldIconGrey.svg';
/**
 * FolderItemUploadFile component description.
 *
 * @FolderItemUploadFile
 *
 */

interface FolderItemUploadFileProps {
  name: string;
  edited?: boolean;
  hasSubFolder?: boolean;
  subFolders?: any[];
  type?: 'Folder' | 'SubFolder';
  onEnterAddFolder?: () => void;
  onChangeNameFolder?: (name: string) => void;
  cancelEdit?: () => void;
  selected?: boolean;
  onSelect?: () => void;
  children?: ReactNode;
  // editItemImg?: (event: React.MouseEvent<HTMLDivElement>) => void;
  //onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FolderItemUploadFile: React.FC<FolderItemUploadFileProps> = ({
  name,
  edited,
  onEnterAddFolder,
  onChangeNameFolder,
  cancelEdit,
  selected,
  onSelect,
  children,
  type,
  hasSubFolder,
}) => {
  return (
    <>
      <div className="FolderItemUploadFile " onClick={onSelect}>
        <div
          className={`folder-wrapper fl_row ai_c jc_s pd12-t-b pd16-r-l ${selected ? 'selected' : ''}`}
          style={{
            marginLeft: type == 'SubFolder' ? 15 : 0,
          }}
        >
          <div className="fl_row gp8 ai_c">
            <img className="iconfolder" src={FolderIconGrey} alt="" />
            {!edited ? (
              <p title={name} className="p14 folder-name">
                {name}
              </p>
            ) : (
              <input
                autoComplete="off"
                onBlur={cancelEdit}
                autoFocus
                onChange={(e) =>
                  onChangeNameFolder
                    ? onChangeNameFolder(e.target.value)
                    : undefined
                }
                type="text"
                defaultValue={name}
                onKeyDown={(e) =>
                  e.key == 'Enter' && onEnterAddFolder
                    ? onEnterAddFolder()
                    : undefined
                }
              ></input>
            )}
          </div>
          {hasSubFolder && <img src={RightArrowBoldIconGrey} alt="" />}
        </div>
      </div>
      {children}
    </>
  );
};

export default FolderItemUploadFile;
