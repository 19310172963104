import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import DoubleUpArrow from '../assets/icons/DoubleArrowRightGrey.svg';
import ErrorIconRed from '../assets/icons/ErrorIconRed.svg';
interface CardFiledsProps {
  children: ReactNode;
  haslabel?: boolean;
  label?: string;
  className?: string;
  contentClass?: string;
  collapse?: boolean;
  collapsing?: boolean;
  // checkboxLabel?: string;
  hasCheckbox?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  errorMsg?: string;
}
const CardFileds: React.FC<CardFiledsProps> = ({
  children,
  haslabel,
  label,
  className,
  contentClass,
  collapse,
  collapsing,
  // checkboxLabel,
  hasCheckbox,
  onChange,
  isChecked,
  errorMsg,
}) => {
  const [ExtendCard, setExtendCard] = useState(true);
  const HandelCard = () => {
    setExtendCard((prevState) => !prevState);
  };
  useEffect(() => {
    console.log({ collapsing });
    if (collapsing != undefined) setExtendCard(!collapsing);
  }, [collapsing]);
  return (
    <div
      className={`CardFileds  fl_col rd16 ${className ? className : ''} ${collapse ? 'collapse' : ''} ${ExtendCard ? 'ExtendCard' : ''}`}
    >
      <div className="card-wrapper fl_col gp5 pd12-t-b pd16-r-l tva-fields">
        {haslabel && (
          <div className="card-header fl_row jc_s ai_c ">
            <div className="fl_row ai_e gp8 w-full pd15-t-b">
              {hasCheckbox ? (
                <div className="fl_row gp10">
                  <input
                    autoComplete="off"
                    checked={isChecked}
                    type="checkbox"
                    onChange={onChange}
                  />
                  <label className={`txt-nowrap p14 OxfordBlue02-txt w-500`}>
                    {label}
                  </label>
                </div>
              ) : (
                <p className="p12 w-700 DarkBlueGray-txt txt-nowrap">{label}</p>
              )}
              <span></span>
            </div>
            <img
              className="ArrowsCard pd5"
              src={DoubleUpArrow}
              alt=""
              onClick={HandelCard}
            />
          </div>
        )}
        <div className={`card-content fl_wrap ${contentClass ? contentClass : ''}`}>
          {children}
        </div>
      </div>
      {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsg">
          <img src={ErrorIconRed} alt="" />
          <p className=" p12 VenetianRed-text">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};
export default CardFileds;
