import { useEffect, useState } from 'react';
import { serviceRolesModels } from '../../../models/services/module.role';
import PermissionBox from '../../../components/UserRoles/RolePermissionItemBox';
import PermissionItem from '../../../components/UserRoles/PermissionItem';
import Modal from '../../../components/Modal/Modal';
import FieldsAccessRightsModalContent from '../../../components/Modal/FieldsAccessRightsModalContent';
import {
  filterNestedArray,
  checkboxes,
  permissionBoxesConfig,
  getFilteredCheckboxes,
} from '../../../utils/filterNestedArray';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';

interface IClaims {
  claims: serviceRolesModels.RolePermission[];
  setRolePermissions: (data: any) => void;
  currentSection: string;
  activeTabContentID?: string;
  selectedRole?: any;
}

interface Items {
  [currentSection: string]: {
    generalItems?: serviceRolesModels.RolePermissionValue[];
    ficheItems?: serviceRolesModels.RolePermissionValue[];
    informationFinanciereItems?: serviceRolesModels.RolePermissionValue[];
    LicencesItems?: serviceRolesModels.RolePermissionValue[];
    responsableItems?: serviceRolesModels.RolePermissionValue[];
  };
}
interface PermissionBoxConfig {
  name: string;
  key: keyof Items[string];
  primary?: boolean;
  onClickMore?: () => void;
}
const initialItems = {
  Entreprise: {
    generalItems: [],
    ficheItems: [],
    informationFinanciereItems: [],
    LicencesItems: [],
    responsableItems: [],
  },
  Etablissement: {
    generalItems: [],
    ficheItems: [],
    informationFinanciereItems: [],
    LicencesItems: [],
    responsableItems: [],
  },
  Roles: {
    generalItems: [],
    ficheItems: [],
    informationFinanciereItems: [],
    LicencesItems: [],
    responsableItems: [],
  },
};
export default function PermissionsConfigurationContainer({
  claims,
  setRolePermissions,
  currentSection = 'Entreprise',
}: IClaims) {
  const [items, setItems] = useState<Items>(initialItems);
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );

  useEffect(() => {
    if (claims && !items[currentSection]?.generalItems?.length) {
      const nestedArrayRoles = {
        generalItems: filterNestedArray(claims, [
          currentSection,
          'Géneral',
        ]).filter((item) =>
          getFilteredCheckboxes(currentSection, 'generalItems').some(
            (checkbox) => checkbox.value === item.value,
          ),
        ),
        ficheItems: filterNestedArray(claims, [currentSection, 'Fiche']).filter(
          (item) =>
            getFilteredCheckboxes(currentSection, 'ficheItems').some(
              (checkbox) => checkbox.value === item.value,
            ),
        ),
        informationFinanciereItems: filterNestedArray(claims, [
          currentSection,
          'Information financière',
        ]).filter((item) =>
          getFilteredCheckboxes(
            currentSection,
            'informationFinanciereItems',
          ).some((checkbox) => checkbox.value === item.value),
        ),
        LicencesItems: filterNestedArray(claims, [
          currentSection,
          'Licences',
        ]).filter((item) =>
          getFilteredCheckboxes(currentSection, 'LicencesItems').some(
            (checkbox) => checkbox.value === item.value,
          ),
        ),
        RolesItems: filterNestedArray(claims, [currentSection, 'Roles']).filter(
          (item) =>
            getFilteredCheckboxes(currentSection, 'RolesItems').some(
              (checkbox) => checkbox.value === item.value,
            ),
        ),
        responsableItems: filterNestedArray(claims, [
          currentSection,
          'Responsable(s)',
        ]),
      };

      setItems((prevItems) => {
        const newItem = JSON.parse(JSON.stringify(prevItems));
        newItem[currentSection] = nestedArrayRoles;
        return newItem;
      });
    }
  }, [claims, currentSection]);

  const updateRolePermissions = (
    prevPermissions: serviceRolesModels.RolePermissionsData,
    title: string,
    value: string,
    newCheckedState: boolean,
  ): serviceRolesModels.RolePermissionsData => {
    const updatedPermissions = JSON.parse(
      JSON.stringify(prevPermissions),
    ) as serviceRolesModels.RolePermissionsData;

    let section = updatedPermissions.rolePermissions.find(
      (s) => s.section === currentSection,
    );

    if (!section) {
      section = { section: currentSection, subSection: [] };
      updatedPermissions.rolePermissions.push(section);
    }

    let subSection = section.subSection.find((ss) => ss.title === title);

    if (!subSection) {
      subSection = { title, values: [] };
      section.subSection.push(subSection);
    }

    let valueItem = subSection.values.find((v) => v.value === value);

    if (!valueItem) {
      valueItem = { value, isChecked: newCheckedState };
      subSection.values.push(valueItem);
    } else {
      valueItem.isChecked = newCheckedState;
    }

    return updatedPermissions;
  };

  const handleCheckboxChange = (title: string, value: string) => {
    const category = permissionBoxesConfig?.find(
      (config) => config.name === title,
    )?.key;

    if (!category) return;

    setItems((prevItems) => {
      const updatedItems = JSON.parse(JSON.stringify(prevItems)) as Items;
      const itemList =
        updatedItems[currentSection][category as keyof Items[string]]?.map(
          (item) => {
            if (item.value === value) {
              return { ...item, isChecked: !item.isChecked };
            }
            return item;
          },
        ) || [];

      const updatedItemIndex = itemList?.findIndex(
        (item) => item.value === value,
      );
      if (updatedItemIndex === -1) {
        itemList.push({ value, isChecked: true });
      }

      updatedItems[currentSection][category as keyof Items[string]] = itemList;

      const updatedItem = itemList?.find((item) => item.value === value);
      const newCheckedState = updatedItem ? updatedItem.isChecked : true;

      setRolePermissions(
        (prevPermissions: serviceRolesModels.RolePermissionsData) =>
          updateRolePermissions(prevPermissions, title, value, newCheckedState),
      );

      return updatedItems;
    });
  };

  return (
    <div className="PermissionsConfigurationContainer">
      <Modal
        version={'custom'}
        title={'Fiche'}
        visibility={false}
        content={<FieldsAccessRightsModalContent />}
        maxWidth={856}
        height={445}
        save
        cancel
      />
      {/* <div className="container-wrapper fl_col gp10">
        <div className="Permission-header white-bg rd12 pd10">
          {currentSection === 'Roles' ? <>hello world</> : ''}
          {(permissionBoxesConfig as PermissionBoxConfig[])
            .slice(0, 1)
            .map(({ name, key, primary }, index) => (
              <PermissionBox name={name} primary={primary} key={index}>
                {getFilteredCheckboxes(currentSection, key).map((checkbox) => {
                  const matchedItem = items[currentSection][key]?.find(
                    (item) => item.value === checkbox.value,
                  );

                  const uniqueId = `${key}-${checkbox.value}`;
                  return (
                    <PermissionItem
                      key={checkbox.value}
                      name={checkbox.label}
                      id={uniqueId}
                      checked={matchedItem?.isChecked}
                      onChange={() =>
                        handleCheckboxChange(name, checkbox.value)
                      }
                    />
                  );
                })}
              </PermissionBox>
            ))}
        </div>
        <div className="Permission-listing white-bg rd12 pd10">
          {(permissionBoxesConfig as PermissionBoxConfig[])
            .filter(
              ({ key }) =>
                key !== 'LicencesItems' || currentSection !== 'Entreprise',
            )
            .slice(1)
            .map(({ name, key, onClickMore }, index) => (
              <PermissionBox name={name} onClickMore={onClickMore} key={index}>
                {getFilteredCheckboxes(currentSection, key).map((checkbox) => {
                  const matchedItem = items[currentSection][key]?.find(
                    (item) => item.value === checkbox.value,
                  );

                  const uniqueId = `${key}-${checkbox.value}`;
                  return (
                    <PermissionItem
                      key={checkbox.value}
                      name={checkbox.label}
                      id={uniqueId}
                      checked={matchedItem?.isChecked}
                      onChange={() =>
                        handleCheckboxChange(name, checkbox.value)
                      }
                    />
                  );
                })}
              </PermissionBox>
            ))}
        </div>
      </div> */}
      <div className="container-wrapper fl_col gp10">
        <div className="Permission-header white-bg rd12 pd10">
          {(permissionBoxesConfig as PermissionBoxConfig[])
            .slice(0, 1)
            .map(({ name, key, primary }, index) => {
              const sectionTitle = currentSection === 'Roles' ? 'Roles' : name; 
              return (
                <PermissionBox
                  name={sectionTitle}
                  primary={primary}
                  key={index}
                >
                  {getFilteredCheckboxes(currentSection, key).map(
                    
                    (checkbox) => {
                      const matchedItem = items[currentSection][key]?.find(
                        (item) => item.value === checkbox.value,
                      );

                      const uniqueId = `${key}-${checkbox.value}`;
                      return (
                        <PermissionItem
                          key={checkbox.value}
                          name={checkbox.label}
                          id={uniqueId}
                          checked={matchedItem?.isChecked}
                          onChange={() =>
                            handleCheckboxChange(name, checkbox.value)
                          }
                        />
                      );
                    },
                  )}
                </PermissionBox>
              );
            })}
        </div>
        {currentSection !== 'Roles' ? (
          <div className="Permission-listing white-bg rd12 pd10">
            {(permissionBoxesConfig as PermissionBoxConfig[])
              .filter(
                ({ key }) =>
                  key !== 'LicencesItems' || currentSection !== 'Entreprise',
              )
              .slice(1)
              .map(({ name, key, onClickMore }, index) => (
                <PermissionBox
                  name={name}
                  onClickMore={onClickMore}
                  key={index}
                >
                  {getFilteredCheckboxes(currentSection, key).map(
                    (checkbox) => {
                      const matchedItem = items[currentSection][key]?.find(
                        (item) => item.value === checkbox.value,
                      );

                      const uniqueId = `${key}-${checkbox.value}`;
                      return (
                        <PermissionItem
                          key={checkbox.value}
                          name={checkbox.label}
                          id={uniqueId}
                          checked={matchedItem?.isChecked}
                          onChange={() =>
                            handleCheckboxChange(name, checkbox.value)
                          }
                        />
                      );
                    },
                  )}
                </PermissionBox>
              ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
