import React, { useState } from 'react';
import ArticleDishIconGrey from '../../../../assets/icons/ArticleDishIconGrey.svg';
import MenusBookIconGrey from '../../../../assets/icons/MenusBookIconGrey.svg';
import SearchIconPrimary from '../../../../assets/icons/SearchIconPrimary.svg';

import InputField from '../../../../components/Input/Input';
import SelectInputCatalogue from '../../../../components/Input/SelectedInputCatalogue';
import CheckBox from '../../../../components/Catalogue/Checkbox';
import ItemStock from '../../../../container/Catalogue/EtatDeStock/ItemStock';
import Modal from '../../../../components/Modal/Modal';
import UserRoleModalContent from '../../../../components/Modal/UserRoleModalContent';
import { languageData } from '../../../../i18n';
import EditStockModalContent from '../../../../container/Catalogue/EtatDeStock/EditStockModalContent';
import EtatDeStockCard from '../../../../container/Catalogue/EtatDeStock/EtatDeStockCard';
export default function EtatDeStock() {
  const [showModalEdit, setShowModalEdit] = useState(false);
  return (
    <div className="EtatDeStockContainer  ">
      <Modal
        version="custom"
        content={
          <EditStockModalContent
            slot={5}
            onClickCancel={() => setShowModalEdit(false)}
            onClickEdit={() => alert('Edit')}
            onClickinStock={() => 'onClickinStock'}
            onClicksoldOut={() => 'onClicksoldOut'}
            onClickexhausted={() => 'onClickexhausted'}
          >
            <EtatDeStockCard
              name={'3 Viandes 31cm'}
              currentStatus={'in-stock'}
              oldStatus="exhausted"
              onClickDelete={() => alert('Delete')}
            />
            <EtatDeStockCard
              name={'3 Viandes 31cm'}
              currentStatus={'exhausted'}
              onClickDelete={() => alert('Delete')}
            />
            <EtatDeStockCard
              name={'3 Viandes 31cm'}
              currentStatus={'sold-out-today'}
              onClickDelete={() => alert('Delete')}
            />
            <EtatDeStockCard
              name={'3 Viandes 31cm'}
              currentStatus={'exhausted'}
              onClickDelete={() => alert('Delete')}
            />
          </EditStockModalContent>
        }
        // title={""}
        visibility={showModalEdit}
        maxWidth={720}
        // height={480}
        close
      />
      <div className="header-container">
        <div className="header-wrapper pd16-t-b pd24-r-l ">
          <div className=" tab-switcher-container ai_c fl_row jc_s">
            <p className="p24 AuroMetalSaurus-txt uper w-600">état de stock</p>
            <div className="tab-switcher">
              <div className="tab-wrapper fl_row ai_c">
                <div className="fl_row ai_c pd16 gp10 active-menu">
                  <img src={ArticleDishIconGrey} alt="" />
                  <p className="p16 w-600">Articles</p>
                </div>
                <div className="fl_row ai_c pd16 gp10">
                  <img src={MenusBookIconGrey} alt="" />
                  <p className="p16 w-600">Menus</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container ">
        <div className="filter-section fl_row jc_s pd16-t-b pd24-r-l ai_e">
          <div className="fl_row gp20 ai_c">
            <div className="field-filter fl_col gp10">
              <p className="p14 w-500 OxfordBlue02-txt cap">
                Recherche par article
              </p>
              <div className="search-inp">
                <InputField
                  // reff={refInput}
                  type="text"
                  name="searchCatalogue"
                  placeholder="Recherche..."
                  // value=""
                  icon={SearchIconPrimary}
                  // onChange={() => alert('changing...')}
                  style="search"
                  className="rd20 p10 br1LightGrey"
                />
              </div>
            </div>
            <div className="field-filter fl_col gp10">
              <p className="p14 w-500 OxfordBlue02-txt cap">état</p>
              <SelectInputCatalogue
                hideOptionItem
                onChange={() => alert('')}
                label={''}
                placeholder={'Tous'}
                target="dropdown"
                options={[]}
              />
            </div>
            <div className="field-filter fl_col gp10">
              <p className="p14 w-500 OxfordBlue02-txt cap">Famille</p>
              <SelectInputCatalogue
                hideOptionItem
                onChange={() => alert('')}
                label={''}
                placeholder={'Tous'}
                target="dropdown"
                options={[]}
              />
            </div>
            <div className="field-filter fl_col gp10">
              <p className="p14 w-500 OxfordBlue02-txt cap">Sous-famille</p>
              <SelectInputCatalogue
                hideOptionItem
                onChange={() => alert('')}
                label={''}
                placeholder={'Tous'}
                target="dropdown"
                options={[]}
              />
            </div>
            <CheckBox label="Tous sélectionner" boxType="squareBox" />
          </div>
          <button
            className="edit-stock-btn"
            onClick={() => setShowModalEdit(true)}
          >
            Modifier état de stock • <span className="w-600 pd10-l">{25}</span>
          </button>
        </div>
        <div className="listing-items pd12-t-b pd16-r-l">
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
                    <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
          <ItemStock name={'3 Viandes 31cm'} status={'sold-out-today'} />
          <ItemStock name={'3 Viandes 31cm'} status={'exhausted'} />
          <ItemStock name={'3 Viandes 31cm'} />
        </div>
      </div>
    </div>
  );
}
